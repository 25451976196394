<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <!--    form Dialog-->
    <v-dialog
      v-model="dialog"
      max-width="1200px"
      class="transparent"
    >
      <v-card
        flat
        class="mx-auto my-8 transparent"
      >
        <base-material-card
          color="black"
          flat
        >
          <template v-slot:heading>
            <v-tabs
              v-model="tabs"
              background-color="transparent"
              slider-color="white"
              grow
            >
              <v-tab class="mr-3">
                <v-icon class="me-2">
                  mdi-spa-outline
                </v-icon>
                Details
              </v-tab>
              <v-tab class="mr-3">
                <v-icon class="me-2">
                  mdi-calendar-clock
                </v-icon>
                Schedules
              </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items
            v-model="tabs"
            class="transparent"
          >
            <v-tab-item
              key="1"
            >
              <v-card-text>
                <validation-observer
                  ref="observer"
                >
                  <v-container>
                    <v-row>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Class Name"
                          rules="required"
                        >
                          <v-text-field
                            v-model="editedItem.name"
                            placeholder="Class Name"
                            outlined
                            :error-messages="errors"
                            label="Class Name"
                          />
                        </validation-provider>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Category"
                          rules="required"
                        >
                          <v-select
                            v-model="editedItem.classCategory"
                            outlined
                            :items="classCatList"
                            item-text="name"
                            item-value="_id"
                            :error-messages="errors"
                            label="Select Class Category"
                            single-line
                            return-object
                          />
                        </validation-provider>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Trainer"
                          rules="required"
                        >
                          <v-select
                            v-model="editedItem.trainer"
                            outlined
                            :items="trainerList"
                            item-text="firstName"
                            item-value="_id"
                            label="Select Trainer"
                            :error-messages="errors"
                            single-line
                            return-object
                          />
                        </validation-provider>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Capacity"
                          rules="required"
                        >
                          <v-text-field
                            v-model="editedItem.capacity"
                            placeholder="Capacity"
                            outlined
                            :error-messages="errors"
                            label="Class Capacity"
                            type="number"
                          />
                        </validation-provider>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Price"
                          rules="required"
                        >
                          <v-text-field
                            v-model="editedItem.price"
                            placeholder="Price"
                            outlined
                            prefix="$"
                            type="number"
                            :error-messages="errors"
                            label="Class Price"
                          />
                        </validation-provider>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Facility"
                          rules="required"
                        >
                          <v-select
                            v-model="editedItem.facility"
                            outlined
                            :items="facilityList"
                            item-text="name"
                            item-value="_id"
                            label="Select Facility"
                            :error-messages="errors"
                            single-line
                            return-object
                          />
                        </validation-provider>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-textarea
                          v-model="editedItem.description"
                          placeholder="Description"
                          outlined
                          label="Description"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </validation-observer>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="grey darken-1"
                  text
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  @click="onAddClass"
                >
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Save
                </v-btn>
              </v-card-actions>
            </v-tab-item>
            <!--                  ALL SCHEDULE DETAILS-->
            <v-tab-item
              key="2"
            >
              <!--                Days Selection-->
              <v-row justify="space-around">
                <v-col
                  cols="12"
                  sm="10"
                  md="8"
                >
                  <v-sheet
                    class="py-4 px-1 justify-center"
                  >
                    <v-chip-group
                      show-arrows
                      active-class="primary--text"
                    >
                      <v-chip
                        v-for="day in days"
                        :key="day.id"
                        outlined
                        filter
                        @click="day.click"
                      >
                        <b class="text-h4">{{ day.name }}</b>
                      </v-chip>
                    </v-chip-group>
                  </v-sheet>
                </v-col>
              </v-row>
              <!--Days Selection End-->
              <div style="border:1px solid #e3e3e3;height:460px;overflow-y:scroll;border-radius:20px">
                <v-container class="py-4 py-lg-8">
                  <div>
                    <div class="text-uppercase font-weight-bold text-body-2 primary--text mb-2">
                      Time Schedule for <b class="green--text text--darken-3">{{ activeDay }}</b>
                      <!--                      <v-spacer />-->
                      <!--                      <span><v-btn-->
                      <!--                        v-if="editedIndex !== -1"-->
                      <!--                        outlined-->
                      <!--                        @click="addShed"-->
                      <!--                      >Add Schedule</v-btn></span>-->
                    </div>
                  </div>
                  <div class="mt-6">
                    <div
                      v-for="item in daySchedules"
                      :key="item.activeDay"
                      class="mt-4"
                    >
                      <v-card
                        style="border-radius:10px"
                        class="py-4 px-2 elevation-2"
                        color="surface"
                      >
                        <!--                                  Test row-->
                        <v-row
                          no-gutters
                          style="border:1px solid white;border-radius:10px"
                        >
                          <v-col
                            cols="12"
                            sm="6"
                            md="8"
                          >
                            <v-card
                              class="pa-2"
                              flat
                            >
                              <span class="text-h5 mx-2 font-weight-bold">
                                From
                              </span>
                              <span class="">
                                <vue-timepicker
                                  v-model="item.startTime"
                                  format="hh:mm A"
                                />
                              </span>
                              <v-divider vertical />
                              <span class="text-h5 mx-2 font-weight-bold">
                                To
                              </span>
                              <span class="">
                                <vue-timepicker
                                  v-model="item.endTime"
                                  format="hh:mm A"
                                />
                              </span>
                            </v-card>
                          </v-col>
                          <v-col
                            cols="6"
                            md="4"
                          >
                            <v-card
                              class="pa-2"
                              flat
                            >
                              <v-btn
                                small
                                text
                                class="green--text"
                                @click="addShed"
                              >
                                <v-icon
                                  small
                                  class="mr-2"
                                >
                                  mdi-plus
                                </v-icon>
                                Add
                              </v-btn>
                              <v-btn
                                small
                                text
                                class="red--text"
                                @click="removeShed(item)"
                              >
                                <v-icon
                                  small
                                  class="mr-2"
                                >
                                  mdi-delete
                                </v-icon>
                                Remove
                              </v-btn>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card>
                    </div>
                  </div>
                </v-container>
              </div>
              <!--              Card Actions-->
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="grey darken-1"
                  text
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  @click="onAddSchedule"
                >
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Save Schedule
                </v-btn>
              </v-card-actions>
            </v-tab-item>
          </v-tabs-items>
        </base-material-card>
        <!--              here-->
      </v-card>
    </v-dialog>
    <!--    form Dialog-->
    <!--   Reset Form Dialog-->
    <!--    Form Dialog End-->
    <!--    Delete Dialog Start-->
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to delete this client?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="closeDelete"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="deleteItemConfirm"
          >
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--    Delete Dialog end-->

    <!--    All Dialogs End-->
    <!--    Base Material Start -->
    <div v-if="fetchup">
      <v-skeleton-loader
        type="table-heading, list-item-two-line, image, table-tfoot"
      />
    </div>
    <base-material-card
      v-else
      color="black"
    >
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">
          <v-row class="align-content-md-space-between">
            <v-col
              cols="12"
              md="9"
              sm="6"
            >
              <div class="text-h3 font-weight-light">
                <v-icon
                  left
                  class="primary--text"
                >
                  mdi-spa-outline
                </v-icon> Manage Classes <i class="text-caption">Total: <b>{{ total }}</b></i>
              </div>
              <div class="text-subtitle-1 font-weight-light">
                Please manage all Classes
              </div>
            </v-col>
            <v-col
              md="3"
              sm="6"
              style="text-align:right"
            >
              <v-btn
                color="primary"
                dark
                outlined
                class="mb-2 subheading font-weight-bold"
                @click="openDialog"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>
                Add New Class
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </template>
      <v-data-table
        :headers="headers"
        :items="classes"
        sort-by="calories"
        class="elevation-1"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
            small
            text
            class="grey--text"
            @click="editItem(item)"
          >
            <v-icon
              small
              class="mr-2"
            >
              mdi-pencil
            </v-icon>
            Edit
          </v-btn>
          <v-btn
            small
            text
            class="red--text"
            @click="deleteItem(item)"
          >
            <v-icon
              small
              class="mr-2"
            >
              mdi-delete
            </v-icon>
            Delete
          </v-btn>
        </template>
        <template v-slot:no-data>
          <v-btn
            text
            small
            color="primary--text"
            @click="fetchClass"
          >
            <v-icon left>
              mdi-refresh
            </v-icon>Reset
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>
    <!--    Base Material End-->
  </v-container>
</template>
<script>
  /* eslint-disable */
  import VueTimepicker from 'vue2-timepicker'
  // CSS
  import 'vue2-timepicker/dist/VueTimepicker.css'

  import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
  import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

  setInteractionMode('eager')

  extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
  })

  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })

  extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
  })

  extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
  })

  extend('email', {
    ...email,
    message: 'Email must be valid',
  })
  const axios = require('axios')
  export default {
    name: 'ManageClass',
    data () {
      return {
        activeDay:'',
        tempTrainer:'',
        activeClass:'',
        total:0,
        days: [
          {id:1,name:"Monday",click:()=>{ this.activeDay = 'Monday'}},
          {id:2,name:"Tuesday",click:()=>{this.activeDay = 'Tuesday'}},
          {id:3,name:"Wednesday",click:()=>{this.activeDay = 'Wednesday'}},
          {id:4,name:"Thursday",click:()=>{this.activeDay = 'Thursday'}},
          {id:5,name:"Friday",click:()=>{this.activeDay = 'Friday'}},
          {id:6,name:"Saturday",click:()=>{this.activeDay = 'Saturday'}},
          {id:7,name:"Sunday",click:()=>{this.activeDay = 'Sunday'}},
        ],
        selected: [2],
        schedules: [],
        tabs: 0,
        menu: false,
        switch1: false,
        dialog: false,
        fetchup: false,
        dialogDelete: false,
        err:[],
        headers: [
          {
            text: 'Class  Name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
        ],
        classes: [],
        editedIndex: -1,
        editedItem: {
          name: '',
          classCategory:{},
          description:'',
          trainer:{},
          type: 'onsite',
          capacity:0,
          price:0,
          facility:{},
        },
        defaultItem: {
          name: '',
          classCategory:{},
          description:'',
          trainer:{},
          type: 'onsite',
          capacity:0,
          price:0,
          facility:{},
        },
      }
    },

    computed: {
      tabby(){
        return this.tabs;
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Add New Class ' : 'Edit Class '
      },

      trainerList(){
        return this.$store.getters.getTrainerList;
      },
      facilityList(){
        return this.$store.getters.getFacilityList;
      },
      classCatList(){
        return this.$store.getters.getClassCategories;
      },
      getActiveDay(){
        return this.activeDay;
      },
      daySchedules(){
        return this.schedules.filter((e)=>{
           return e.day === this.activeDay;
        });
      },

    },

    watch: {
      tabby(val){
          if(val > 0 && this.activeClass === ''){
            // this.$store.commit('setSnack', { t: 'Please first add class details', c: 'red darken-3' })
            this.tab = 0;
            return false;
          }else{
          }
      },
      dialog (val) {
        val || this.close();
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      getActiveDay(val){
        if(this.daySchedules.length === 0 && this.activeClass !== ""){
          this.schedules.push(
            {
              day:this.activeDay,
              startTime: '',
              endTime: '',
              trainer: this.tempTrainer,
              class: this.activeClass,
              withAdd:false,
              addTime: new Date()
            }
          )
        }else if(this.editedIndex !== -1){ //Is an Edit

        }
        else{
          this.$store.commit('setSnack', { t: 'Please first add class details', c: 'red darken-3' })
        }
      },
    },

    mounted () {
      this.fetchClass()
      this.$store.dispatch('fetchFacilityList')
      this.$store.dispatch('fetchTrainers')
      this.$store.dispatch('fetchClassCategories')
    },

    methods: {
      openDialog(){
        this.dialog = true
        this.schedules = []
        this.tempTrainer = ''
        this.activeClass = ''
      },
      addShed(){
        this.schedules.push(
          {
            day:this.activeDay,
            startTime: '',
            endTime: '',
            class:this.activeClass,
            withAdd:false,
            trainer: this.tempTrainer
          }
        )
      },

      removeShed(payload){
        const index = this.schedules.indexOf(payload);
        this.schedules.splice(index, 1);
      },

      fetchClass () {
        this.fetchup = true
        const self = this
        axios.get(this.apiSet.classes)
          .then(response => {
            const obj = response.data.classes
            console.log({class:response});
            self.classes = obj
            self.total = obj.length
            self.fetchup = false
            // self.$store.commit('setSnack', { t: 'Success. Data was successfully added.', c: 'green darken-3' })
          }).catch(
          e => {
            self.fetchup = false
            this.err.push(e)
          },
        )
      },

      onAddClass() {
        if (this.editedIndex > -1) {
          this.updateClass()
        } else {
          const self = this
          this.$refs.observer.validate().then((result) => {
            if (result) {
              this.loader = true
              const catData = this.editedItem

              axios.post(this.apiSet.classes, this.editedItem)
                .then(function (response) {
                  // self.close()
                  console.log({sharp: response.data.data })
                  let returnClass = response.data.data.class
                  self.tempTrainer = returnClass.trainer
                  self.activeClass = returnClass._id
                  self.fetchClass()
                  self.tab = 1;
                  self.loader = false
                  self.$store.commit('setSnack', { t: 'Success. Data was successfully added.', c: 'green darken-3' })
                })
                .catch(error => {
                  self.loader = false
                  console.log(error)
                  self.close()
                })
            } else {
              self.$store.commit('setSnack', { t: 'Please correct form errors.', c: 'red darken-3' })
              return false
            }
          })
        }
      },

      onAddSchedule() {
        if (this.editedIndex > -1) {
          this.updateSchedule()
        } else {
          const self = this
          // this.$refs.observer.validate().then((result) => {
          //   if (result) {
              this.loader = true
              const catData = this.editedItem

              axios.post(this.apiSet.schedules, {schedules: this.schedules})
                .then(function (response) {
                  self.close()
                  self.fetchClass()
                  self.loader = false
                  self.$store.commit('setSnack', { t: 'Success. Data was successfully added.', c: 'green darken-3' })
                })
                .catch(error => {
                  self.loader = false
                  console.log(error)
                  self.close()

                })
            // } else {
            //   self.$store.commit('setSnack', { t: 'Please correct form errors.', c: 'red darken-3' })
            //   return false
            // }
          // })
        }
      },

      fetchSchedule (scheduleID) {
        this.fetchup = true
        const self = this
        axios.get(this.apiSet.classSchedules+'/'+scheduleID)
          .then(response => {
            const obj = response.data.schedules
            console.log({schedulesetters:response});
            self.schedules = obj
            self.total = obj.length
            self.fetchup = false
            // self.$store.commit('setSnack', { t: 'Success. Data was successfully added.', c: 'green darken-3' })
          }).catch(
          e => {
            self.fetchup = false
            this.err.push(e)
          },
        )
      },


      updateClass () {
        const self = this
        this.loader = true
        const catData = this.editedItem

        axios.put(this.apiSet.class+'/'+this.editedItem._id,
          {
            name: catData.name,
          })
          .then(function (response) {
            self.close()
            self.fetchClass()
            self.loader = false
            self.$store.commit('setSnack', { t: 'Success. Data was successfully updated.', c: 'green darken-3' })
          })
          .catch(error => {
            self.loader = false
            self.err.push(error)
          })
      },
      updateSchedule () {
        const self = this
        this.loader = true
        const catData = this.editedItem

        axios.put(this.apiSet.class+'/'+this.editedItem._id,
          {
            name: catData.name,
          })
          .then(function (response) {
            self.close()
            self.fetchClass()
            self.loader = false
            self.$store.commit('setSnack', { t: 'Success. Data was successfully updated.', c: 'green darken-3' })
          })
          .catch(error => {
            self.loader = false
            self.err.push(error)
          })
      },

      editItem (item) {
        this.editedIndex = this.classes.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
        setTimeout(() => {this.fetchSchedule(item._id)}, 2000)
      },

      deleteItem (item) {
        this.editedIndex = this.classes.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.classes.splice(this.editedIndex, 1)
        const self = this
        this.loader = true
        const delId = this.editedItem._id

        axios.delete(this.apiSet.classes+'/'+delId)
          .then(function (response) {
            self.loader = false
            self.$store.commit('setSnack', { t: 'Success. Data was successfully deleted.', c: 'green darken-3' })
            self.closeDelete()

          })
          .catch(error => {
            self.loader = false
            self.err.push(error)
          })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.classes[this.editedIndex], this.editedItem)
        } else {
          this.classes.push(this.editedItem)
        }
        this.close()
      },
    },
    components: {
      VueTimepicker
    },
  }
</script>

<style lang="scss">
/* Default override (not using "append-to-body") */
.vue__time-picker .dropdown ul li:not([disabled]).active {
  background: #B49640;
}

/* When using "append-to-body" */
.vue__time-picker-dropdown ul li:not([disabled]).active {
  background: #B49640;
}

.vue__time-picker .dropdown ul li:not([disabled]).active:hover,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus {
  color: #B49640;
}

.vue__time-picker input.display-time{
  border-radius: 40px;
}

.vue__time-picker .dropdown ul li:not(.hint):not([disabled]):hover,
.vue__time-picker .dropdown ul li:not(.hint):not([disabled]):focus {
  background: rgba(0, 0, 0, 0.2);
  color: #161616;
  cursor: pointer;
}
</style>
